@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

button {
  @apply bg-trimble-blue text-white shadow rounded p-1 hover:bg-blue-light text-sm;
}

a {
  @apply bg-trimble-blue text-white shadow rounded p-1 hover:bg-blue-light text-sm text-center;
}
